import React from 'react'
import ErrorComp from '../../../components/ErrorComp/ErrorComp'
const QuestionContainer = () => {
  return (
    <div className='app__signalscontainer-content_questions'>
        <ErrorComp />
        <ErrorComp />
        <ErrorComp />
        <ErrorComp />
        <ErrorComp />
        <ErrorComp />
        <ErrorComp />
    </div>
  )
}

export default QuestionContainer