import React from 'react'

const BugContainer = () => {
  return (
    <div className='app__signalscontainer-content_bugs'>
ddd
    </div>
  )
}

export default BugContainer