import React from 'react'

const SMContainer = () => {
  return (
    <div className='app__editcontainer-content_sousmod'>
        <div className="infos">
          infos
        </div>
        <div className="divider"/>

    </div>
  )
}

export default SMContainer