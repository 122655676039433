export const  quotes = [
    "Success is the sum of small efforts repeated day in and day out. - Robert Collier ",
    "The only place where success comes before work is in the dictionary. - Vidal Sassoon",
    "The harder I work, the luckier I get. - Gary Player",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. - Albert Schweitzer",
    "Success is no accident. It is hard work, perseverance, learning, studying, sacrifice, and most of all, love of what you are doing. - Pelé",
    "The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt",
    "The secret to success is to know something nobody else knows. - Aristotle Onassis",
    "The difference between a successful person and others is not a lack of strength, not a lack of knowledge, but rather a lack of will. - Vince Lombardi",
    "Success is not in what you have, but who you are. - Bo Bennett",
    "Success is not the absence of failure it's the persistence through failure. - Aisha Tyler",
    "The only limit to our realization of tomorrow will be our doubts of today. - Franklin D. Roosevelt",
    "Success is not the result of spontaneous combustion. You must set yourself on fire. - Arnold H. Glasow",
    "Success is walking from failure to failure with no loss of enthusiasm. - Winston Churchill",
    "The only way to do great work is to love what you do. - Steve Jobs",
    "The future belongs to those who believe in their ability to learn. - Frank Leahy",
    "Success is the progressive realization of a worthy goal or ideal. - Earl Nightingale",
    "The only thing standing between you and your goal is the story you keep telling yourself as to why you can't achieve it. - Jordan Belfort",
    "Success is not just about making money. It's about making a difference. - Unknown",
    "The mind is everything. What you think, you become. - Buddha",
    "The only person you should try to be better than is the person you were yesterday. - Unknown",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. - Herman Cain",
    "Success is not about being the best. It's about being better than you were yesterday. - Unknown",
    "Don't watch the clock; do what it does. Keep going. - Sam Levenson",
    "Believe you can, and you're halfway there. - Theodore Roosevelt",
    "Success is not in what you have, but who you are. - Bo Bennett",
    "The more I want to get something done, the less I call it work. - Richard Bach",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. - Albert Schweitzer",
    "Success is not the absence of failure; it's the persistence through failure. - Aisha Tyler",
    "Success is not the result of spontaneous combustion. You must set yourself on fire. - Arnold H. Glasow",
    "Success is walking from failure to failure with no loss of enthusiasm. - Winston Churchill",
    "The only way to do great work is to love what you do. - Steve Jobs",
    "The future belongs to those who believe in their ability to learn. - Frank Leahy",
    "Success is the progressive realization of a worthy goal or ideal. - Earl Nightingale",
    "The only thing standing between you and your goal is the story you keep telling yourself as to why you can't achieve it. - Jordan Belfort",
    "Success is not just about making money. It's about making a difference. - Unknown",
    "The mind is everything. What you think, you become. - Buddha",
    "The only person you should try to be better than is the person you were yesterday. - Unknown",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. - Herman Cain",
    "Success is not about being the best. It's about being better than you were yesterday. - Unknown",
    "Don't watch the clock; do what it does. Keep going. - Sam Levenson",
    "Believe you can, and you're halfway there. - Theodore Roosevelt",
    "Success is not in what you have, but who you are. - Bo Bennett",
    "The more I want to get something done, the less I call it work. - Richard Bach",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. - Albert Schweitzer",
    "Success is not the absence of failure; it's the persistence through failure. - Aisha Tyler",
    "Success is not the result of spontaneous combustion. You must set yourself on fire. - Arnold H. Glasow",
    "Success is walking from failure to failure with no loss of enthusiasm. - Winston Churchill",
    "The only way to do great work is to love what you do. - Steve Jobs",
    "The future belongs to those who believe in their ability to learn. - Frank Leahy",
    "Success is the progressive realization of a worthy goal or ideal. - Earl Nightingale",
    "The only thing standing between you and your goal is the story you keep telling yourself as to why you can't achieve it. - Jordan Belfort",
    "Success is not just about making money. It's about making a difference. - Unknown",
    "The mind is everything. What you think, you become. - Buddha",
    "The only person you should try to be better than is the person you were yesterday. - Unknown",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. - Herman Cain",
    "Success is not about being the best. It's about being better than you were yesterday. - Unknown",
    "Don't watch the clock; do what it does. Keep going. - Sam Levenson",
    "Believe you can, and you're halfway there. - Theodore Roosevelt",
    "Success is not in what you have, but who you are. - Bo Bennett",
    "The more I want to get something done, the less I call it work. - Richard Bach",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. - Albert Schweitzer",
    "Success is not the absence of failure; it's the persistence through failure. - Aisha Tyler",
    "Success is not the result of spontaneous combustion. You must set yourself on fire. - Arnold H. Glasow",
    "Success is walking from failure to failure with no loss of enthusiasm. - Winston Churchill",
    "The only way to do great work is to love what you do. - Steve Jobs",
    "The future belongs to those who believe in their ability to learn. - Frank Leahy",
    "Success is the progressive realization of a worthy goal or ideal. - Earl Nightingale",
    "The only thing standing between you and your goal is the story you keep telling yourself as to why you can't achieve it. - Jordan Belfort",
    "Success is not just about making money. It's about making a difference. - Unknown",
    "The mind is everything. What you think, you become. - Buddha",
    "The only person you should try to be better than is the person you were yesterday. - Unknown",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. - Herman Cain",
    "Success is not about being the best. It's about being better than you were yesterday. - Unknown",
    "Don't watch the clock; do what it does. Keep going. - Sam Levenson",
    "Believe you can, and you're halfway there. - Theodore Roosevelt",
    "Success is not in what you have, but who you are. - Bo Bennett",
    "The more I want to get something done, the less I call it work. - Richard Bach",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. - Albert Schweitzer",
    "Success is not the absence of failure; it's the persistence through failure. - Aisha Tyler",
    "Success is not the result of spontaneous combustion. You must set yourself on fire. - Arnold H. Glasow",
    "Success is walking from failure to failure with no loss of enthusiasm. - Winston Churchill",
    "The only way to do great work is to love what you do. - Steve Jobs",
    "The future belongs to those who believe in their ability to learn. - Frank Leahy",
    "Success is the progressive realization of a worthy goal or ideal. - Earl Nightingale",
    "The only thing standing between you and your goal is the story you keep telling yourself as to why you can't achieve it. - Jordan Belfort",
    "Success is not just about making money. It's about making a difference. - Unknown",
    "The mind is everything. What you think, you become. - Buddha",
    "The only person you should try to be better than is the person you were yesterday. - Unknown",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. - Herman Cain",
    "Success is not about being the best. It's about being better than you were yesterday. - Unknown",
    "Don't watch the clock; do what it does. Keep going. - Sam Levenson",
    "Believe you can, and you're halfway there. - Theodore Roosevelt",
    "Success is not in what you have, but who you are. - Bo Bennett",
    "The more I want to get something done, the less I call it work. - Richard Bach",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. - Albert Schweitzer",
    "Success is not the absence of failure; it's the persistence through failure. - Aisha Tyler",
    "Success is not the result of spontaneous combustion. You must set yourself on fire. - Arnold H. Glasow",
    "Success is walking from failure to failure with no loss of enthusiasm. - Winston Churchill",
    "The only way to do great work is to love what you do. - Steve Jobs",
    "The future belongs to those who believe in their ability to learn. - Frank Leahy",
]

